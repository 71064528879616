import * as React from 'react';

import * as ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import App from './App';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
