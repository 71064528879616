import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const darkmodeState = atom({
  key: 'darkMode',
  default: true,
});

export const loadingState = atom({
  key: 'loading',
  default: false,
});

export const apiTokenState = atom({
  key: 'apiToken',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const containersState = atom({
  key: 'containers',
  default: [] as string[],
});

export const containerState = atom({
  key: 'container',
  default: '',
});

// export const partitionKeysState = atom({
//   key: 'partitionKeys',
//   default: [] as string[],
// });

// export const partitionKeyState = atom({
//   key: 'partitionKey',
//   default: '',
// });
