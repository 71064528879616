/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { JsonEditor } from 'json-edit-react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Test } from '../@types/tests';
import TestSelectors from '../components/testSelectors';
import RestUtilities from '../services/RestUtilities';
import {
  apiTokenState,
  containerState,
  loadingState,
} from '../state/RecoilState';

export default function Home() {
  const [loading, setLoading] = useRecoilState(loadingState);
  const container = useRecoilValue(containerState);
  const apiToken = useRecoilValue(apiTokenState);
  const [tests, setTests] = useState<Test[]>([]);
  const [currentTest, setCurrentTest] = useState<Test | undefined>(undefined);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [editedJson, setEditedJson] = useState<any | undefined>(undefined);
  const [idToDelete, setIdToDelete] = useState<GridRowId | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchTests = async () => {
      setLoading(true);
      const res = await RestUtilities.getLiteTests(container, apiToken);
      if (res.ok) {
        setTests(res.content);
      }
      setLoading(false);
    };
    if (container && apiToken) {
      fetchTests();
    }
  }, [apiToken, container, setLoading]);

  useEffect(() => {
    const fetchTest = async () => {
      setLoading(true);
      const res = await RestUtilities.getTest(container, testId, apiToken);
      if (res.ok) {
        setCurrentTest(res.content);
      }
      setLoading(false);
    };
    const testId = rowSelectionModel[0] as string;
    if (testId) {
      fetchTest();
    }
  }, [apiToken, container, rowSelectionModel, setLoading]);

  const handleRowSelectionChange = async (
    newRowSelectionModel: GridRowSelectionModel
  ) => {
    setEditedJson(undefined);
    setCurrentTest(undefined);
    if (newRowSelectionModel.length > 1) {
      const selectionSet = new Set(rowSelectionModel);
      const result = newRowSelectionModel.filter((s) => !selectionSet.has(s));
      setRowSelectionModel(result);
    } else {
      setRowSelectionModel(newRowSelectionModel);
    }
  };

  const handleUpdateTest = async () => {
    setLoading(true);
    const res = await RestUtilities.putTest(
      container,
      editedJson.newData,
      apiToken
    );
    if (currentTest?.id !== editedJson.newData.id) {
      const newTests = [...tests];
      newTests.push(res.content);
      setTests(newTests);
    }
    setLoading(false);
  };

  const setUserToDelete = useCallback(
    (id: GridRowId) => () => {
      setIdToDelete(id);
    },
    []
  );

  const clearDeleteUser = () => {
    setIdToDelete(undefined);
  };

  const deleteUser = async () => {
    const testToDelete = tests.find((t) => t.id === idToDelete);
    const newTests = tests.filter((t) => t.id !== idToDelete);
    setTests(newTests);
    setIdToDelete(undefined);
    await RestUtilities.deleteTest(container, testToDelete, apiToken);
  };

  const DeleteUserActionItem = ({
    setUserToDelete,
    ...props
  }: GridActionsCellItemProps & { setUserToDelete: () => void }) => {
    return (
      <React.Fragment>
        <GridActionsCellItem {...props} onClick={setUserToDelete} />
      </React.Fragment>
    );
  };

  const columns = useMemo<GridColDef<Test>[]>(
    () => [
      {
        field: 'partitionKey',
        headerName: 'Group',
        width: 120,
        valueGetter: (value, row) => row.partitionKey,
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 340,
        renderCell: (params) => {
          const split = params.row.id.split('.');
          return split[1];
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <DeleteUserActionItem
            key={'deleteButton'}
            label='Delete'
            icon={<DeleteIcon />}
            setUserToDelete={setUserToDelete(params.id)}
          />,
        ],
      },
    ],
    [setUserToDelete]
  );

  return (
    <Box sx={{ p: 2 }}>
      <TestSelectors />
      <Box sx={{ minHeight: '10px', mt: 2 }}>
        {loading && <LinearProgress />}
      </Box>
      <Box sx={{ maxWidth: '100%' }} display='flex' justifyContent='flex-start'>
        <Box sx={{ height: 650, width: 544, mt: 1 }}>
          <DataGrid
            rows={tests}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                {
                  display: 'none',
                },
            }}
            pageSizeOptions={[25]}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleRowSelectionChange}
          />
        </Box>
        {currentTest && (
          <>
            <Box mt={1} ml={2}>
              <Button
                variant='outlined'
                disabled={!editedJson}
                onClick={handleUpdateTest}
              >
                Save Changes
              </Button>
            </Box>
            <Box
              px={2}
              mt={1}
              ml={2}
              sx={{
                position: 'relative',
                border: '1px solid rgba(81, 81, 81, 1)',
                borderRadius: 1,
              }}
            >
              <JsonEditor
                data={currentTest}
                onUpdate={(data: unknown) => setEditedJson(data)}
                maxWidth={'100%'}
                indent={2}
                collapse
                theme={[
                  'githubDark',
                  {
                    container: {
                      backgroundColor: '#121212',
                      fontFamily: 'monospace',
                    },
                  },
                ]}
              />
            </Box>
          </>
        )}
      </Box>
      <Dialog open={!!idToDelete} onClose={clearDeleteUser}>
        <DialogTitle id='alert-dialog-title'>
          Do you want to delete the test permanently?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This will delete the test from the CosmosDb. There is no coming back
            from this.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearDeleteUser}>Cancel</Button>
          <Button onClick={deleteUser} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
