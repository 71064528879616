import React from 'react';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { darkmodeState } from '../state/RecoilState';

const Layout: React.FC = () => {
  const [darkMode, setDarkMode] = useRecoilState(darkmodeState);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static' style={{ backgroundColor: '#E51D2F' }}>
          <Toolbar>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              TeamX Api Test Harness Bundle Editor
            </Typography>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => setDarkMode(!darkMode)}
              color='inherit'
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </div>
  );
};

export default Layout;
